<template lang="pug">
b-card(body-class="p-0")
  b-row
    b-col(cols="6")
        b-tabs(pills card lazy)
          b-tab(
            v-for="(tab, index) in tabs"
            :key="index"
            :title="`${tab.title || tab.states[0]} (${tab.states.length > 0 ? estado_count(tab.states[0]) : estado_count('')})`"
            :title-item-class="isSearched ? 'd-none' : 'text-black'"
            :active="selectedTab === index"
            @click="handleTabChange(index)"
          )
    b-col(cols="6")
        b-input-group.mt-2  
          b-input-group-prepend
          
          b-form-input(v-model="searchData" placeholder="Búsqueda por OT, Número de Serie o RMA")
          b-input-group-append.ml-2.mr-2
            b-button(@click="handleClear" variant="outline-primary")
              b-icon(icon="x" variant="outline-primary" size="md") 
            b-button(@click="handleSearch" variant="outline-primary") Buscar            
  b-row 
    b-col(cols="12")
      b-table(
              :items="currentTableItems"
              :fields="fields"
              head-row-variant="light-primary"
              thead-class="text-black tabla-thead"
              tbody-class="tabla-tbody"
              tbody-tr-class="bg-white"
              responsive
              :busy="loading"
              show-empty
              empty-text="No hay Ordenes de Trabajo en este estado"
            )
              template(#cell(ot)="value")
                .d-flex.justify-content-start
                  router-link(:to="`orden/${value.item.ot}?from=excepciones`" :style="getColorOT(value.item.estado)")
                    | {{ value.item.ot }}
              template(#table-busy)
                .text-center.text-danger.my-2
                  b-spinner(class="align-middle")
              template(#cell(acciones)="value")
                .d-flex.flex-row
                  b-button(size="sm" @click="editExcepcion(value.item.acciones)" class="mr-2")
                    | Editar
              .d-flex.flex-row-reverse
      b-pagination(
                  v-model="currentPage"
                  :total-rows="currentExceptions.count"
                  :per-page="pageSize"
                  prev-text="Ant."
                  next-text="Sig."
                  @change="handlePageChange"
                )
    //- template(#tabs-end)
    //-   li.nav-item.align-self-center.spacing(role="presentation")
    //-     b-button(v-if="$route.name == 'Excepciones para ot'" size="sm" @click="createExcepcion()") Crear Excepcion
  // Modal Para Crear/Editar excepciones
  CreateEditForm(
    :orden_trabajo="otId"
    @change="handleTabChange(selectedTab)"
    @save="fetchData"
  )
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
// import SearchException from './SearchException.vue';
import CreateEditForm from './CreateEditForm.vue'

export default {
  name: 'ExcepctionList',
  components: {
    // SearchException,
    CreateEditForm,
  },
  data() {
    return {
      tabs: [
        { title: 'Solicitado', states: ['solicitado'] },
        { title: 'Aprobado', states: ['aprobado'] },
        { title: 'Rechazado', states: ['rechazado'] },
        { title: 'Todas', states: [] },
      ],
      user: null,
      roles: null,
      currentPage: 1,
      pageSize: 20,
      selectedTab: 0,
      loading: false,
      isBusy: false,
      isLoad: false,
      excepcion_data: null,
      updateTimeInterval: null,
      otId: null,
      searchData: null,
    }
  },
  async created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.roles = user.groups.map((t) => t.id)
    await this.fetchExceptions()
    this.$emit('stop-loading')
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibility, false)
    this.updateTimeInterval = setInterval(() => {
      this.updateOtTimes()
    }, 60000)
  },
  computed: {
    // ...mapGetters('AuthenticationStore', ['user']),
    ...mapGetters('Exceptions', [
      'currentExceptions',
      'currentStates',
      'exception',
      'isCreated',
      'isSearched',
    ]),
    /** Transforma las ot en formato requerido para tabla bootstrap */
    currentTableItems() {
      if (!this.currentExceptions.results) return []
      if (this.roles.indexOf(1) > -1) {
        return this.currentExceptions.results.map((e) => { //eslint-disable-line
          //eslint-disable-line
          return {
            ot: e.orden_trabajo,
            solicitado_por: e.solicitado_por
              ? `${e.solicitado_por.first_name} ${e.solicitado_por.last_name}`
              : '-',
            // respondido_por: e.respondido_por ? `${e.respondido_por.first_name} ${e.respondido_por.last_name}` : '-',
            estado: e.estado ? e.estado : 'Sin estado',
            tipo: e.tipo ? e.tipo : '-',
            fecha_hora_creacion: this.getDate(e.fecha_hora_creacion),
            // fecha_hora_respuesta: this.getDate(e.fecha_hora_respuesta),
            // comentario_solicitud: e.comentario_solicitud ? e.comentario_solicitud : '-',
            // comentario_respuesta: e.comentario_respuesta ? e.comentario_respuesta : '-',
            rma: e.rma ? e.rma : '-',
            serial_number: e.serial_number ? e.serial_number : '-',
            acciones: e,
          }
        })
      }

      return this.currentExceptions.results.map((e) => { //eslint-disable-line
        return {
          ot: e.orden_trabajo,
          solicitado_por: e.solicitado_por
            ? `${e.solicitado_por.first_name} ${e.solicitado_por.last_name}`
            : '-',
          respondido_por: e.respondido_por
            ? `${e.respondido_por.first_name} ${e.respondido_por.last_name}`
            : '-',
          estado: e.estado ? e.estado : 'Sin estado',
          fecha_hora_creacion: this.getDate(e.fecha_hora_creacion),
          fecha_hora_respuesta: this.getDate(e.fecha_hora_respuesta),
          comentario_solicitud: e.comentario_solicitud
            ? e.comentario_solicitud
            : '-',
          comentario_respuesta: e.comentario_respuesta
            ? e.comentario_respuesta
            : '-',
          rma: e.rma ? e.rma : '-',
          serial_number: e.serial_number ? e.serial_number : '-',
        }
      })
    },
    /** Reduce las tabs a arreglo de estados para pasárselos a los filtros de búsqueda */
    estados() {
      let states = []
      if (this.tabs[this.selectedTab].states.length > 0) {
        states = this.tabs[this.selectedTab].states.map((t) => t)
      }
      return states
    },
    fields() {
      const fields = [
        { key: 'ot', label: 'O.T', stickyColumn: true },
        { key: 'fecha_hora_creacion', label: 'Fecha' },
        { key: 'tipo' },
        { key: 'estado' },
        { key: 'solicitado_por' },
        // { key: 'respondido_por' },
        // { key: 'comentario_solicitud' },
        // { key: 'comentario_respuesta' },
        // { key: 'fecha_hora_respuesta', label: 'Fecha de Respuesta' },
        { key: 'rma', label: 'RMA' },
        { key: 'serial_number', label: 'Número de\xa0Serial' },
      ]
      if (this.roles.indexOf(1) > -1) {
        fields.push({ key: 'acciones', label: 'Acciones' })
        return fields
      }
      return fields
    },
  },
  methods: {
    ...mapActions('Exceptions', ['searchException']),
    ...mapMutations('Exceptions', [
      'SET_SHOW_MODAL',
      'SET_EXCEPTION',
      'SET_IS_CREATED',
    ]),
    handleVisibility() {
      if (document.hidden) {
        clearInterval(this.updateTimeInterval)
      } else {
        this.updateTimeInterval = setInterval(() => {
          this.updateOtTimes()
        }, 60000)
        this.updateOtTimes()
      }
    },
    async updateOtTimes() {
      if (this.$route.name === 'Excepciones') {
        await  this.searchException({ estado: this.tabs[this.selectedTab].states })
      }
    },  
    getColorOT(estado) {
      let style = 'color:#41509B; font-weight: bold'
      if (estado) {
        const state = estado
        if (state === 'Solicitado') {
          style = 'color:#7F828F; font-weight: bold'
        }
      }
      return style
    },
    getDate(date) {
      if (date) {
        const formatedDate = moment(date).format('DD/MM/YYYY')
        return formatedDate
      }
      return '-'
    },
    estado_count(value) {
      if (this.currentExceptions.estados) {
        if (value === 'solicitado') {
          return this.currentExceptions.estados.solicitados
        }
        if (value === 'aprobado') {
          return this.currentExceptions.estados.aprobados
        }
        if (value === 'rechazado') {
          return this.currentExceptions.estados.rechazados
        }
        if (this.currentExceptions.estados.todas)
          return this.currentExceptions.estados.todas
      }
    },
    handlePageChange(page) {
      this.fetchData(page)
    },
    handleTabChange(index) {
    this.selectedTab = index;
    this.searchData = '';
    this.fetchData();
  },
    async handleSearch() {
      if (this.searchData === '' || this.searchData === null) return;
      await this.fetchData();
      this.selectedTab = 3;
    },
    async handleClear() {
      if (this.searchData === '' || this.searchData === null) return;
      this.searchData = '';
      await this.fetchData(this.currentPage);      
    },
    async fetchData(page = 1) {
      this.loading = true
      const payload = {
        estado: this.tabs[this.selectedTab].states,
        page,
        search: this.searchData,
      }
      if (this.$route.name === 'Excepciones para ot') {
        payload.ot = parseInt(this.$route.params.otId, 10)
      }
      await this.searchException(payload)
      this.loading = false
    },
    getSecondEnterData(ordenes) {
      const urls = []
      if (Array.isArray(ordenes)) {
        // eslint-disable-next-line
        ordenes.map((o) => {
          urls.push(`<a href="#/orden/${o.ot}" target="_blank"> ${o.ot} </a>`)
        })
        return urls.join(', ')
      }
      return `<a href="#/orden/${ordenes}" target="_blank"> ${ordenes} </a>`
    },
    createExcepcion() {
      if (this.exception) {
        this.SET_EXCEPTION({ exception: null })
      }
      this.SET_SHOW_MODAL(true)
    },
    editExcepcion(value) {
      this.SET_EXCEPTION({ exception: value })
      this.SET_SHOW_MODAL(true)
    },
    async fetchExceptions() {
      if (this.$route.name === 'Excepciones para ot') {
        this.otId = parseInt(this.$route.params.otId, 10)
        await this.searchException({
          estado: this.tabs[0].states,
          ot: parseInt(this.$route.params.otId, 10),
        })
        return
      }
      await this.searchException({ estado: this.tabs[0].states })
    },
  },
  watch: {
    isCreated(value) {
      if (value) {
        this.fetchExceptions()
        this.SET_IS_CREATED(null)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.ver-pedido {
  color: #fff;
  background-color: #5b73e8;
  border-color: #5b73e8;
  width: 90px;
  height: 40px;
  border-radius: 5px;
  font-weight: 600;
  &:disabled {
    background-color: #7d93ff;
    border-color: #7d93ff;
    cursor: not-allowed;
  }
}
.status {
  font-weight: bold;
  width: fit-content;
  padding: 10px;
  border-radius: 6px;
  margin: auto;
}
.ot {
  color: #41509b;
  font-weight: bold;
}
.header-tecnico {
  font-size: 1.2em;
  font-weight: 700;
}
::v-deep .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5b73e8;
  font-weight: bold;
}
::v-deep .card-header {
  background-color: #ffffff;
  overflow-x: auto;

  ul {
    overflow-x: visible;
  }

  a {
    color: #495057;
    font-weight: bold;
    &:hover {
      color: #495057;
    }
  }
}
::v-deep table th {
  color: #495057;
}
a.router-link-active {
  color: #5b73e8;
}
.text-danger {
  color: #5b73e8 !important;
}
::v-deep .card-body {
  padding: 0;
}

.second-admission {
  background-color: #f1b44c;
  color: #495057;
  margin-left: 3px;
  margin-top: -3px;
}

.text-danger[data-v-76755bd8] {
  color: #d32b2b !important;
}
@media (min-width: 930px) {
  .spacing {
    margin-left: 37%;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5b73e8 !important;
  font-weight: bold;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #5b73e8 !important;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

::v-deep .tabla-tbody td {
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

::v-deep .tabla-thead th {
  @include media-breakpoint-down(sm) {
    padding: 4px 10px 4px;
  }
}
</style>
