import axios from '../../plugins/axios'
import searchFilter from '../../utils/searchFilter'

export const listOT = ({ commit }, page = 1) => {
  if (page === 1) {
    const apiAddress = 'api/orden/?page=1' // Cuando este lista la api, se coloca la direccion
    const { key } = JSON.parse(localStorage.getItem('user'))
    const config = {
      headers: { Authorization: `Token ${key}` },
    } // Cuando este listo el back coloco la configuracion para el header del request
    return axios.get(apiAddress, config).then((response) => {
      commit('SET_OTS', { ots: response.data })
    })
  }
  const apiAddress = `api/orden/?page=${page}` // Cuando este lista la api, se coloca la direccion
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  } // Cuando este listo el back coloco la configuracion para el header del request
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_OTS', { ots: response.data })
  })
}

export const searchAdvancedFilter = (
  { commit },
  { query, estados, fecha_ingreso, tecnico_asignado_id }
) => {
  const apiAddress = searchFilter(
    query,
    estados,
    fecha_ingreso,
    tecnico_asignado_id
  )
  console.log(apiAddress)
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  } // Cuando este listo el back coloco la configuracion para el header del request
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_OTS', { ots: response.data })
  })
}

export const searchOT = ({ commit }, ot) => {
  const apiAddress = `api/orden/?ot=${ot}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_OTS', { ots: response.data })
  })
}

export const createOT = async ({ commit }, data) => {
  // eslint-disable-line
  const apiAddress = '/api/orden/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(
      apiAddress,
      { ...data, equipo_id: data.equipo, cliente_id: data.cliente },
      config
    )
    .then((response) => {
      commit('SET_STATUS', response)
      return response
    })
    .catch((error) => {
      commit('SET_STATUS', error.response)
    })
}

export const editOT = ({ commit }, data) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/${data.ot}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .put(apiAddress, data, config)
    .then((response) => {
      commit('SET_STATUS', response)
    })
    .catch((error) => {
      console.log({ error })
      commit('SET_STATUS', error.response)
    })
}

export const getOT = async ({ commit }, otId, setMutation = true) => {
  // eslint-disable-line
  console.log('get', otId)
  const apiAddress = `/api/orden/${otId}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  try {
    const response = await axios.get(apiAddress, config)
    if (setMutation) commit('SET_OT', { ot: response.data })
    return response.data
  } catch (e) {
    commit('SET_STATUS', e)
    throw e
  }
}

// eslint-disable-next-line
export const getOTForActions = async ({ commit }, otId) => {
  const apiAddress = `/api/orden/${otId}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  try {
    const response = await axios.get(apiAddress, config)
    return response.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const cleanOT = async ({ commit }) => {
  // eslint-disable-line
  commit('SET_OT', { ot: null })
}

export const getOTPublic = async ({ commit }, { otId, rut }) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/${otId}?rut=${rut}`
  try {
    const response = await axios.get(apiAddress)
    console.log('no hay error')
    commit('SET_OT', { ot: response.data })
    return response.data
  } catch (e) {
    console.log('hay error')
    commit('SET_STATUS', e)
  }
}

export const getOTHistoryPublic = async ({}, { otId, rut }) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/${otId}/historial?rut=${rut}`
  return axios.get(apiAddress)
    .catch((error) => error.response)
}

export const OTExcellAll = ({}, ots) => {
  const apiAddress = `/api/orden/generar_excel`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.post(apiAddress, { ots },config).then((response) => {
    return response
  })
}

export const OTExcellByDate = ({}, date) => {
  const apiAddress = `/api/orden/generar_excel_por_fecha?fecha_inicio=${date.fecha_inicio}&fecha_final=${date.fecha_final}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    return response
  })
}

export const OTExcelFromFile = ({}, { file, fields } = {}) => {
  const apiAddress = '/api/orden/generar_excel_desde_archivo'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = { headers: { Authorization: `Token ${key}` } }
  const formData = new FormData()
  formData.append('input', file)
  formData.append('fields', fields)
  return axios.post(apiAddress, formData, config)
}

export const getExcelFields = ({}) => {
  const apiAddress = '/api/orden/get_excel_fields'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = { headers: { Authorization: `Token ${key}` } }
  return axios.get(apiAddress, config)
}
export const getExcelPlantilla = ({}, type) => {
  const apiAddress = `/api/orden/get_plantilla_base?type=${type}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
    responseType: 'blob',
  }
  return axios.get(apiAddress, config)
}

export const uploadFile = ({ commit }, data) => {
  const apiAddress = '/api/orden/upload'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      Authorization: `Token ${key}`,
      'Content-Type': 'multipart/form-data',
    },
  }
  console.log({ data })
  return axios
    .post(apiAddress, data, config)
    .then((response) => {
      commit('SET_STATUS', response)
      getOT({ commit }, data.get('orden_trabajo'))
      return response
    })
    .catch((error) => {
      commit('SET_STATUS', error.response)
      return error.response
    })
}

export const getFiles = ({ commit }, ot) => {
  const apiAddress = `api/orden/${ot}/lista_archivos`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_FILES', response.data.archivos)
  })
}

export const deleteFile = ({}, fileId) => {
  console.log(fileId)
  const apiAddress = `api/orden/delete_file/${fileId}`
  console.log(apiAddress)
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.delete(apiAddress, config)
}

export const getFileUrl = ({}, payload) => {
  const apiAddress = `api/orden/download/${payload.fileId}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
    responseType: 'blob',
  }
  let content_type = ''
  if (payload.fileType === 'png') {
    content_type = 'image/png'
  }
  if (payload.fileType === 'jpg') {
    content_type = 'image/jpg'
  }
  if (payload.fileType === 'pdf') {
    content_type = 'application/pdf'
  }
  return axios.get(apiAddress, config).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: content_type,
      })
    )
    return url
  })
}

export const downloadFile = ({}, payload) => {
  const apiAddress = `api/orden/download/${payload.fileId}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
    responseType: 'blob',
  }
  let content_type = ''
  if (payload.fileType === 'png') {
    content_type = 'image/png'
  }
  if (payload.fileType === 'jpg') {
    content_type = 'image/jpg'
  }
  if (payload.fileType === 'pdf') {
    content_type = 'application/pdf'
  }
  return axios.get(apiAddress, config).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: content_type,
      })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', payload.fileName)
    document.body.appendChild(link)
    link.click()
  })
}

export const downloadReport = ({}, otId) => {
  const apiAddress = `api/orden/download_report/${otId}`
  const content_type = 'application/pdf'
  const config = {
    responseType: 'blob',
  }
  return axios.get(apiAddress, config).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: content_type,
      })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `informe_ot_${otId}.pdf`)
    document.body.appendChild(link)
    link.click()
  })
}

export const downloadPdfCambio = ({}, payload) => {
  const apiAddress = `api/orden/download_pdf_cambio/${payload.ot}`
  const content_type = 'application/pdf'
  const config = {
    responseType: 'blob',
  }
  return axios.get(apiAddress, config).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: content_type,
      })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      `certificado-cambio-rma-${payload.rma}-ot-${payload.ot}.pdf`
    )
    document.body.appendChild(link)
    link.click()
  })
}
export const downloadPdfCotizacion = ({}, ot) => {
  const apiAddress = `api/orden/download_pdf_cotizacion/${ot}`
  const content_type = 'application/pdf'
  const config = {
    responseType: 'blob',
  }
  return axios.get(apiAddress, config).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: content_type,
      })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'certificado_cambio.pdf')
    document.body.appendChild(link)
    link.click()
  })
}

export const getPdfIngreso = ({}, ot) => {
  const apiAddress = `api/orden/get_pdf_ingreso/${ot}`
  const config = {
    responseType: 'blob',
  }
  return axios.get(apiAddress, config).then((response) => response).catch((e) => e)
}

// export const sendEmailClient = ({}, ot) => {
//   const apiAddress = `api/orden/${ot}/email`;
//   const { key } = JSON.parse(localStorage.getItem('user'));
//   const config = {
//     headers: { Authorization: `Token ${key}` },
//   };
//   return axios.post(apiAddress, {}, config)
//     .then((response) => {
//       console.log(response.data);
//       return response;
//     }).catch((error) => {
//       console.log(error.response);
//       return error.response;
//     });
// };

export const searchStoreByName = ({}, tienda) => {
  const apiAddress = 'api/orden/tienda'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, tienda, config)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const getStoreList = ({ commit }) => {
  const apiAddress = 'api/orden/tienda'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, {}, config)
    .then((response) => {
      commit('SET_STORE_LIST', { storeList: response.data })
    })
    .catch((error) => {
      console.log(error)
    })
}

export const createStore = ({}, tienda) => {
  const apiAddress = 'api/orden/crear-tienda'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, tienda, config)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const sendChangeClientEmail = ({}, ot) => {
  const apiAddress = `api/orden/${ot}/email-cambio`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.post(apiAddress, {}, config).then((response) => response.status)
}

export const saveChangeClient = ({}, payload) => {
  const apiAddress = `api/orden/${payload.ot}/guardar-cambio`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      Authorization: `Token ${key}`,
      'Content-Type': 'multipart/form-data',
    },
  }
  return axios
    .post(apiAddress, payload.form, config)
    .then((response) => response.status)
}

export const getCourier = ({ commit }) => {
  const apiAddress = 'api/orden/courier'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_COURIER', { couriers: response.data })
  })
}

export const getDistribuidores = ({ commit }) => {
  const apiAddress = 'api/orden/distribuidor'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_DISTRIBUIDORES', { distribuidores: response.data })
  })
}

// Transiciones de estado
export const asignNewTechnitian = ({}, { otId, technitianId }) => {
  console.log({ otId, technitianId })
  const apiAddress = `api/orden/${otId}/transiciones/asignar_nuevo_tecnico`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = { tecnico_asignado_id: technitianId }
  return axios.post(apiAddress, data, config)
}

export const updateTechnitian = ({}, { otId, technitianId }) => {
  console.log({ otId, technitianId })
  const apiAddress = `api/orden/${otId}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = { tecnico_asignado: technitianId }
  return axios.put(apiAddress, data, config)
}

export const partArrival = ({}, { otId, partId }) => {
  console.log({ otId, partId })
  const apiAddress = `api/orden/${otId}/transiciones/avisa_llegada_de_parte`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = { parte_solicitada_id: partId }
  return axios.post(apiAddress, data, config)
}

export const partArrivalAll = ({}, { otId, partsId }) => {
  console.log({ otId, partsId })
  const apiAddress = `api/orden/${otId}/transiciones/avisa_llegada_de_partes`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = { partes_solicitadas_ids: partsId }
  return axios.post(apiAddress, data, config)
}

export const entregarPartes = ({}, { otId }) => {
  console.log({ otId })
  const apiAddress = `api/orden/${otId}/transiciones/entregar_partes_solicitadas`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = {}
  return axios.post(apiAddress, data, config)
}
// Entregar a recepcion

export const entregarRecepcion = ({}, movimientoBodega) => {
  console.log('entregar a ', movimientoBodega)
  const apiAddress = `api/orden/${movimientoBodega.ot}/transiciones/entregar_recepcion`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = movimientoBodega
  return axios.post(apiAddress, data, config)
}


export const devolverBodega = ({}, data) => {
  const apiAddress = `api/orden/${data.ot}/transiciones/devolver_bodega`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.post(apiAddress, data, config)
}

// Entrega final
export const entregaFinal = ({}, movimientoBodega) => {
  console.log('entregaFinal', { movimientoBodega })
  const apiAddress = `api/orden/${movimientoBodega.ot}/transiciones/entrega_final`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = movimientoBodega
  return axios.post(apiAddress, data, config)
}

// Desarme
export const desarme = ({}, { otId, rmaDesarme, asignadoDesarme }) => {
  const apiAddress = `api/orden/${otId}/transiciones/desarme`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = { rma_desarme: rmaDesarme, asignado_desarme_id: asignadoDesarme }
  return axios.post(apiAddress, data, config)
}

// Reingreso
export const reingreso = ({}, { otId, informeDeFalla }) => {
  const apiAddress = `api/orden/${otId}/transiciones/reingreso`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = { informe_de_falla: informeDeFalla }
  return axios.post(apiAddress, data, config)
}

// Completar preingreso
export const completPreIngreso = ({ commit }, data) => {
  const apiAddress = `api/orden/${data.ot}/transiciones/completar_preingreso`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const apiData = {
    ...data,
    equipo_id: data.equipo,
    cliente_id: data.cliente,
    tienda_id: data.tienda,
  }
  delete apiData.equipo
  delete apiData.cliente
  delete apiData.tienda
  return axios
    .post(apiAddress, apiData, config)
    .then((response) => {
      commit('SET_STATUS', response)
      return response
    })
    .catch((error) => {
      commit('SET_STATUS', error.response)
    })
}

// Rechazar preingreso
export const rechazarPreIngreso = ({ commit }, data) => {
  const apiAddress = `api/orden/${data.ot}/transiciones/rechazar_preingreso`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const apiData = {
    comment: data.comment,
  }
  return axios
    .post(apiAddress, apiData, config)
    .then((response) => {
      commit('SET_STATUS', response)
      return response
    })
    .catch((error) => {
      commit('SET_STATUS', error.response)
    })
}

export const recibirEquipo= (_, ot) => {
  const apiAddress = `api/orden/${ot}/transiciones/recibir_equipo`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, {}, config)
    .then((response) => response)
    .catch(() => {})
}

// Entregar equipo a técnico
export const entregarEquipoTecnico = ({}, { otId, technitianId }) => {
  const apiAddress = `api/orden/${otId}/transiciones/entregar_equipo_a_tecnico`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = { tecnico_asignado_id: technitianId }
  return axios.post(apiAddress, data, config)
}

// Entregar equipo a técnico
export const entregarEquipoTecnicoSinPartes = ({}, { otId, technitianId }) => {
  const apiAddress = `api/orden/${otId}/transiciones/entregar_equipo_a_tecnico_sin_partes`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = { tecnico_asignado_id: technitianId }
  return axios.post(apiAddress, data, config)
}


export const changeServiceType = ({}, { otId, data }) => {
  const apiAddress = `api/orden/${otId}/change_service_type`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.post(apiAddress, data, config)
}
