<template lang="pug">
  div
    b-dropdown( variant="button")
      template(#button-content)
        span.font-weight-bold Exportar
        b-icon(icon ="box-arrow-in-right")
      b-dropdown-text.export-text Exportar:
      b-dropdown-item(@click="exportAll()")
        b-icon(icon ="box-arrow-in-right").mr-2
        | Vista Actual
      b-dropdown-item(@click="showExportRangeModal = true")
        b-icon(icon ="calendar3").mr-2
        | Rango de Fecha
      b-dropdown-item(@click="showExportFromFileModal = true")
        b-icon(icon ="calendar3").mr-2
        | Desde archivo de OTs
    b-modal(v-model="showExportRangeModal" title="Exportar Rango Fechas" hide-footer)
      b-form-group
        label.label-color Desde
        b-form-datepicker(v-model ="start_date")
      b-form-group
        label.label-color Hasta
        b-form-datepicker(v-model="end_date")
      div.row
        div.col.p-2.d-flex.flex-row.justify-content-end
          b-button(
            @click="closeModal()"
            variant="outline-danger"
          ) Cancelar
          b-button.ml-2(
            @click="exportExcel()"
            variant="button"
          ) Exportar

    //- TODO: mover estos modals a componentes
    b-modal(v-model="showExportFromFileModal" title="Exportar desde archivo" hide-footer)
      .bg-grey.mb-3.px-3.py-2.border.rounded
        .d-flex.gap-3.align-items-center
          b-avatar(variant="info" size="2rem")
            b-icon(font-scale="1.5" icon="info")
          div.text-justify
            | El archivo ingresado debe tener una columna de datos con uno de los siguientes textos
            | en la primera celda: OT, RMA, N_SERIE
      b-dropdown.mb-2( variant="button" size="sm" menu-class="w-100 dropdown-menu" text="Descargar Plantilla")
        b-dropdown-item(@click="downloadTemplate('OT')") OT 
        b-dropdown-item(@click="downloadTemplate('RMA')") RMA
        b-dropdown-item(@click="downloadTemplate('N_SERIE')") N° de Serie
      b-form-group
        label.label-color Seleccionar archivo
        b-form-file(
          v-model="inputFile"
          accept=".xlsx, .xls"
          placeholder="Archivo"
          @input=""
        )
      b-form-group
        label.label-color Seleccionar campos
        TagsFilter.fields-filter(
          v-model="inputFields"
          :options="fieldOptions"
          idKey="field"
          labelKey="label"
          input-id="fields-selector"
          selectorLabel="Seleccionar campos"
        )
      div.row
        div.col.p-2.d-flex.flex-row.justify-content-end
          b-button(
            @click="showExportFromFileModal = false"
            variant="outline-danger"
          ) Cancelar
          b-button.ml-2(
            @click="exportFromFile"
            variant="button"
          ) Exportar

</template>

<script>
import { mapActions } from 'vuex'
import TagsFilter from '@/components/Shared/TagsFilter.vue'

export default {
  name: 'DownloadOTs',
  components: {
    TagsFilter,
  },
  props: ['otsFiltered'],
  data() {
    return {
      showExportRangeModal: false,
      start_date: null,
      end_date: null,
      showExportFromFileModal: false,
      inputFile: null,
      inputFields: [],
      fieldOptions: [],
    }
  },
  watch: {
    showExportFromFileModal(val) {
      if (!val) {
        this.inputFile = null
        this.inputFields = []
        return
      }
      this.getExcelFields().then((res) => {
        this.fieldOptions = res.data
      })
    },
  },
  methods: {
    ...mapActions('OTStore', [
      'OTExcellAll',
      'OTExcellByDate',
      'OTExcelFromFile',
      'getExcelFields',
      'getExcelPlantilla',
    ]),
    closeModal() {
      this.start_date = null
      this.end_date = null
      this.showExportRangeModal = false
    },
    async downloadTemplate (type) {
      const resp = await this.getExcelPlantilla( type )
      const url = window.URL.createObjectURL(new Blob([resp.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `plantilla_${type}.xls`)
      document.body.appendChild(link)
      link.click()
      link.remove() 
    },
    async exportAll() {
      const resp = await this.OTExcellAll(this.otsFiltered.join(','))
      this.$bvToast.toast(resp.data, {
        variant: 'success',
      })
    },
    async exportExcel() {
      const date = {
        fecha_inicio: this.start_date,
        fecha_final: this.end_date,
      }
      const resp = await this.OTExcellByDate(date)
      this.$bvToast.toast(resp.data, {
        variant: 'success',
      })
      this.closeModal()
    },
    async exportFromFile() {
      const payload = {
        file: this.inputFile,
        fields: this.inputFields.map((f) => f.field).join(','),
      }
      this.OTExcelFromFile(payload).then((resp) => {
        this.$bvToast.toast(resp.data, {
          variant: 'success',
        })
        this.showExportFromFileModal = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .form-group label {
  color: #6c757d !important;
  font-weight: bold;
}
::v-deep .dropdown-item {
  font-size: 0.875rem;
}
::v-deep .b-dropdown-text {
  font-size: 0.875rem;
}
::v-deep .dropdown-item:hover {
  color: #fff;
  background-color: #5b73e8 !important;
  border-color: #5b73e8 !important;
}
.fields-filter::v-deep {
  .dropdown-menu {
    max-height: calc(90vh - 380px) !important;
  }
}
</style>
